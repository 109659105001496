.filter-card {
  .card-block {
    padding: $layout-vgap;
  }
}

clipping-table {
  table.mat-table {
    width: 100%;
  }
}
