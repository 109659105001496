.right-menu-container {
  display: flex;
  flex-direction: row;
  flex: 1 1 100%;

  .sBar {
    flex: 0 0 30px;
    height: 100%;
    padding-top: 10px;
    width: 30px;
    display: block;
    position: relative;

    &.active {
      background: linear-gradient(to top right, rgba(217, 217, 217, 0.1), white, white, #d9d9d9);

      &:hover {
        background: linear-gradient(to left, rgba(217, 217, 217, 1), rgba(217, 217, 217, 0.1));
        border: 1px solid rgba(217, 217, 217, 1);
      }

      cursor: pointer;
    }

    &.s-3 {
      background: #d27b26;
    }

    &.s-4 {
      background: #f3b200;
    }

    &.inactive {
      background: #dc572e;
    }
  }

}
