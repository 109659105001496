// Start with assigning color names to specific hex values.
$white:  #fff !default;
$black:  #000 !default;
$red:    #d9534f !default;
$orange: #f0ad4e !default;
$yellow: #ffd500 !default;
$green:  #5cb85c !default;
$blue:   #0275d8 !default;
$teal:   #5bc0de !default;
$pink:   #ff5b77 !default;
$purple: #613d7c !default;



//$main-color: #274983;
$main-color: rgb(0,0,0);
//$brand-green: #23a190;
//$brand-green: rgb(140,140,140);
$brand-green: (89, 89, 89);
//$brand-gray: rgb(140,140,140);
$brand-gray: rgb(89, 89, 89);
$brand-blue: rgb(39, 73, 131);
$secondary_color: rgb(61, 130, 199); //rgb(0, 139, 197);
$brand-gray: #d9d9d9;
$brand-lightgray: #f4f4f4;

$brand-primary:             $secondary_color !default;
$brand-success:             $brand-green !default;
$brand-info:                $teal !default;
$brand-warning:             $orange !default;
$brand-danger:              $red !default;
$brand-inverse:             $brand-blue !default;

// Create grayscale
$gray-dark:                 #292b2c !default;
$gray:                      #464a4c !default;
$gray-light:                #636c72 !default;
$gray-lighter:              #eceeef !default;
$gray-lightest:             #f7f7f9 !default;
$white:  #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:  #000 !default;

$grays: (
        100: $gray-100,
        200: $gray-200,
        300: $gray-300,
        400: $gray-400,
        500: $gray-500,
        600: $gray-600,
        700: $gray-700,
        800: $gray-800,
        900: $gray-900
) !default;


$btn-dropdown-toggle-bg: $brand-green;

$text-muted: $gray-600 !default;
$text-disabled: $gray-100 !default;

$overview-bg: rgb(217, 217, 217);
$title-size: 28px;
$header-bg: $overview-bg;
$box-shadow: 0 2px 5px rgba(0, 0, 0, .3);


$layout-vgap: 10px;
$layout-hgap: 15px;
$layout-section-header-title-height: 24px;
$layout-width: 1140px;
$layout-sidebar-width: 300px;
$layout-sidebar-inner-width: $layout-sidebar-width - 2*$layout-hgap;
$layout-content-width: $layout-width - $layout-sidebar-width + 150px;
$layout-content-right-width: 50px;
$layout-top-title-line-height: 2rem;
$layout-top-title-padding-top: .3rem;
$layout-top-title-padding-bottom: .75rem;
$layout-top-toolbar-height: 2rem;
$layout-top-title-height: 2*$layout-top-title-line-height+$layout-top-title-padding-top+$layout-top-title-padding-bottom;
$layout-top-height: $layout-top-title-height+$layout-top-toolbar-height;
$layout-top-smallheight: $layout-top-title-line-height+$layout-top-title-padding-top+$layout-top-title-padding-bottom+$layout-top-toolbar-height;


$sector-iconbar-width: 200px;
$sector-iconbar-height: 120px;
$sector-iconbar-margin-left: 20px;
$sector-iconbar-margin: 5px;

$sector-iconbar-mobile-width: 100px;
$sector-iconbar-mobile-height: 60px;
$sector-iconbar-mobile-margin-left: 10px;
$sector-iconbar-mobile-margin: 5px;

$sector-chart-title-height: 25px;
$sector-vertical-gap: 5px;
$margin: 5px;
$sidebar-body-padding-top: $sector-chart-title-height;

$enable-sidebar-nav-rounded:        false;
$sidebar-color:                     black;
$sidebar-borders:                   none;
$sidebar-nav-title-padding-y:       .5rem;
$sidebar-nav-title-padding-x:       .75rem;
$sidebar-nav-link-padding-y:        .5rem;
$sidebar-nav-link-padding-x:        .75rem;
$sidebar-nav-link-color:            $brand-green;
$sidebar-nav-link-bg:               transparent;
$sidebar-nav-link-icon-color:       $text-muted;

$sidebar-nav-link-borders:          0;
$sidebar-nav-link-hover-color:      white;
$sidebar-nav-link-hover-bg:         $brand-green;
$sidebar-nav-link-hover-icon-color: white !important;

$sidebar-nav-link-hover-borders:    0;
$sidebar-nav-link-active-bg:        darken(white, 5%);

$sidebar-nav-link-active-borders:   0;

$sidebar-nav-dropdown-color:        #fff;
$sidebar-nav-dropdown-bg:           rgba(0,0,0,.2);
$sidebar-nav-dropdown-borders:      0;

$top-nav-color: black;
$top-nav-font-size: 100%;
$top-bg: $brand-gray;
$navbar-toggler-padding-x: 0;

//
//Fonts
$font-family-serif: 'Adwired', Georgia, "Times New Roman", Times, serif !default;
$font-family-sans-serif: 'Adwired', -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$font-family-base: $font-family-sans-serif !default;
$font-family-bold: 'AdwiredBold', 'Adwired', Georgia, "Times New Roman", Times, serif !default;

$mat-font-family: $font-family-serif;

$font-size-base: 0.75rem;
$small-font-size: 0.625rem;

$link-hover-decoration: none;

//Brand Table
//$brand-table-bg: $brand-lightgray;
//$brand-table-hidden-bg: red;
//$brand-table-selected-bg: $brand-green;
//$brand-table-selected-color: white;

$brand-table-row-border-bottom: 1px solid black;
$brand-table-margin-top: 10px;
$brand-table-body-height: 200px;
$brand-table-cell-padding-left: 20px;
$brand-table-padding-right: $margin;
$brand-table-bg: #f4f4f4;
$brand-table-selected-color: white;
$top-background: #d9d9d9;
$nav-title-bg: #d9d9d9;

//
$border-radius: 0;


$sector-to-sector-margin: 10px;

$card-icon-color: white;
$card-icon-bg: transparent;
