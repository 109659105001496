.btn-brand {
  color: white;
  background-color: $main-color;
}

.btn-primary-action {
  color: white;
  background-color: $main-color;
  border-radius: 9px;
}


.btn-brand-bordered, .btn-brand-bordered:hover {
  color: white;
  background-color: $main-color;
  border: 1px solid white;
}

.btn-brand-bordered:hover {
  color: $main-color;
  background-color: white;
  border: 1px solid white;
}

.btn-brand.active {
  color: white;
  //border: 1px solid white;
}


.btn-brand-inverse {
  color: $main_color;
  background-color: transparent;
  //border: 1px solid map-get($tbt-theme,'main-color');;
}

.btn-brand-inverse.active {
  color: $main_color;
  //border: 1px solid map-get($tbt-theme,'main-color');;
}


.btn-small {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.125rem 0.125rem;
  font-size: 0.5rem;
  line-height: 1;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}


.btn.btn-block.btn-norm, .btn.btn-half-block.btn-norm {
  display: flex;
  height: 30px;

  .btn-icon {
    flex: 0 0 18px;
    line-height: 18px;
    height: 18px;
  }

  .btn-title {
    flex: 1 1 100%;
    line-height: 18px;
    height: 18px;
    text-align: left;
  }
}


button.btn.btn-half-block {
  display: flex;
  flex-direction: row;
  flex: 1 1 50%;
}


