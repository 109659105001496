$mdi-font-path:        "/assets/fonts" !default;
@import "@mdi/font/scss/materialdesignicons";
@import "./styles/fontface";
@import "./styles/variables";
@import "./styles/mixins";
@import "./styles/bootstrap_variables";
@import "./themes";
@import "./styles/style";
@import "./styles/flags";
@import "default_theme";
@import "swagger-ui-dist/swagger-ui.css";

// @import 'tailwindcss/base';
// @import 'tailwindcss/components';
@import 'tailwindcss/utilities';
