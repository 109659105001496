button:focus {outline:0;}
//
// Utilities for common `display` values
//

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-down($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .d#{$infix}-down-none         { display: none !important; }
    // .d#{$infix}-inline       { display: inline !important; }
    // .d#{$infix}-inline-block { display: inline-block !important; }
    // .d#{$infix}-block        { display: block !important; }
    // .d#{$infix}-table        { display: table !important; }
    // .d#{$infix}-table-cell   { display: table-cell !important; }
    // .d#{$infix}-flex         { display: flex !important; }
    // .d#{$infix}-inline-flex  { display: inline-flex !important; }
  }
}


// ######################
// SIZE HELPERS
// ######################
@each $prop, $abbrev in (height: h, width: w) {

  @for $index from 0 through 128 {
    $size: $index * 4;
    $length: #{$size}px;

    .#{$abbrev}-#{$size} {
      #{$prop}: $length !important;
      min-#{$prop}: $length !important;
      max-#{$prop}: $length !important;
    }
  }

  // Percentage
  @for $i from 0 through 20 {
    $i-p: 5 * $i;
    $size-p: 5% * $i;

    .#{$abbrev}-#{$i-p}-p {
      #{$prop}: $size-p !important;
    }
  }
}



@each $breakpoint in map-keys($grid-breakpoints) {

  @include media-breakpoint-up($breakpoint) {

    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (margin: m, padding: p) {

      @for $index from 0 through 64 {
        $size: $index * 4;
        $length: #{$size}px;

        .#{$abbrev}#{$infix}-#{$size} {
          #{$prop}: $length !important;
        }
      }

      @for $index from 0 through 64 {
        $size: $index * 4;
        $length: #{$size}px;

        .#{$abbrev}x#{$infix}-#{$size} {
          #{$prop}-right: $length !important;
          #{$prop}-left: $length !important;
        }

        .#{$abbrev}y#{$infix}-#{$size} {
          #{$prop}-top: $length !important;
          #{$prop}-bottom: $length !important;
        }
      }

      @for $index from 0 through 64 {
        $size: $index * 4;
        $length: #{$size}px;

        .#{$abbrev}t#{$infix}-#{$size} {
          #{$prop}-top: $length !important;
        }

        .#{$abbrev}r#{$infix}-#{$size} {
          #{$prop}-right: $length !important;
        }

        .#{$abbrev}b#{$infix}-#{$size} {
          #{$prop}-bottom: $length !important;
        }

        .#{$abbrev}l#{$infix}-#{$size} {
          #{$prop}-left: $length !important;
        }
      }

      @if ($abbrev == m) {
        @for $index from 0 through 64 {
          $size: $index * 4;
          $length: #{$size}px;

          // Some special margin utils for flex alignments
          .m#{$infix}-auto {
            margin: auto !important;
          }

          .mt#{$infix}-auto {
            margin-top: auto !important;
          }

          .mr#{$infix}-auto {
            margin-right: auto !important;
          }

          .mb#{$infix}-auto {
            margin-bottom: auto !important;
          }

          .ml#{$infix}-auto {
            margin-left: auto !important;
          }

          .mx#{$infix}-auto {
            margin-right: auto !important;
            margin-left: auto !important;
          }

          .my#{$infix}-auto {
            margin-top: auto !important;
            margin-bottom: auto !important;
          }
        }
      }
    }
  }
}
