$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
@import "@fortawesome/fontawesome-free/scss/fontawesome";
@import "@fortawesome/fontawesome-free/scss/regular";
@import "@fortawesome/fontawesome-free/scss/solid";
@import "@fortawesome/fontawesome-free/scss/brands";
@import "@fortawesome/fontawesome-free/scss/v4-shims";

@import 'swiper/swiper';
@import 'perfect-scrollbar/css/perfect-scrollbar';

@import "../bootstrap/functions";
@import "../bootstrap/variables";
@import "../bootstrap/mixins";
//@import "../bootstrap/print";
@import "../bootstrap/reboot";
//@import "../bootstrap/type";
//@import "../bootstrap/images";
//@import "../bootstrap/code";
@import "../bootstrap/grid";
@import "../bootstrap/tables";
@import "../bootstrap/forms";
@import "../bootstrap/buttons";
//@import "../bootstrap/transitions";
@import "../bootstrap/dropdown";
@import "../bootstrap/button-group";
@import "../bootstrap/input-group";
@import "../bootstrap/custom-forms";
@import "../bootstrap/nav";
@import "../bootstrap/navbar";
@import "../bootstrap/card";
//@import "../bootstrap/breadcrumb";
//@import "../bootstrap/pagination";
@import "../bootstrap/badge";
//@import "../bootstrap/jumbotron";
//@import "../bootstrap/alert";
//@import "../bootstrap/progress";
//@import "../bootstrap/media";
//@import "../bootstrap/list-group";
@import "../bootstrap/close";
@import "../bootstrap/modal";
//@import "../bootstrap/tooltip";
@import "../bootstrap/popover";
//@import "../bootstrap/carousel";
@import "../bootstrap/utilities/align";
@import "../bootstrap/utilities/background";
@import "../bootstrap/utilities/borders";
@import "../bootstrap/utilities/clearfix";
@import "../bootstrap/utilities/display";
@import "../bootstrap/utilities/embed";
@import "../bootstrap/utilities/flex";
//@import "../bootstrap/utilities/float";
@import "../bootstrap/utilities/position";
//@import "../bootstrap/utilities/screenreaders";
@import "../bootstrap/utilities/sizing";
@import "../bootstrap/utilities/spacing";
@import "../bootstrap/utilities/text";
@import "../bootstrap/utilities/visibility";

//@import "global-scroll";
@import "typography";
@import "loading";
@import "layout";
@import "admin_layout";
@import "top";
@import "card";
@import "sidebar";
@import "popover";
@import "buttons";
@import "modal";
@import "switches";
@import "toast";

@import "components/components";
@import "styles";
@import "zoom";
@import "utils";
@import "material";


@import 'global-scroll';
@import 'perfect-scrollbar/css/perfect-scrollbar.css';
@import 'dragula';


//@include ngx-datatable($default-theme);
//@include switches($default-theme);
//@include modal($default-theme);
HTML, BODY {
  //@include global-scroll($main-color);
  //@include adwMaterial($default-theme);
}

.text-disabled {
  color: rgba(0, 0, 0, 0.3) !important;
}

.dropdown-menu {
  position: static;
}

.white-icon {
  color: white;
}

/* Note: If you're using an SVG icon, you should make the class target the `<svg>` element */
.white-icon svg {
  fill: white;
}

.modal-close-button.pull-right {
  margin-top: -6px;
}

.logo-login {
  /*height: 90px;*/
  height: 30px;
  background-repeat: no-repeat;
}

.navbar-brand {
  object-fit: cover;
  width: 100%;
  height: 100%;
  background-position-x: left;
  background-position-y: top;
  margin-top: 15px;
}

hr {
  width: 100%;
  display: block;
  unicode-bidi: isolate;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-inline-start: auto;
  margin-inline-end: auto;
  overflow: hidden;
  border-style: solid;
  border-width: 1px;
  border-bottom: 0;
}

.ps {
  position: relative;

  > .ps__rail-x {
    z-index: 99999;
  }

  > .ps__rail-y {
    z-index: 99999;
    opacity: 100;
    left: auto !important;
  }
}

.ps {
  .ps__rail-y:hover > .ps__thumb-y, .ps__rail-y:focus > .ps__thumb-y, .ps__rail-y.ps--clicking .ps__thumb-y {
  }
}


input[type=text]::-ms-clear {
  display: none;
}


.primary-bg.mat-button-toggle.mat-button-toggle-standalone.mat-button-toggle-appearance-standard.mat-button-toggle-checked {
  background: lightgray !important;
  color: black !important;
}
