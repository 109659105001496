// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-rounded: false !default;
$enable-shadows: false !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-hover-media-query: false !default;
$enable-grid-classes: true !default;
$enable-print-styles: true !default;

$grid-breakpoints: (
        xs: 0,
        sm: 375px,
        md: $layout-width - 1,
        lg: $layout-width,
        xl: $layout-width+1
) !default;

$spacer: 1rem !default;
$spacer-x: $spacer !default;
$spacer-y: $spacer !default;

$spacers: (
        0: 0,
        1: ($spacer),
        2: ($spacer * 2),
        3: $spacer*3,
        4: ($spacer * 4),
        5: ($spacer * 5)
) !default;

$card-border-width: 0px !default;
$card-spacer-y: .25rem !default;


$btn-focus-box-shadow:           none;
$btn-active-box-shadow:          none;
