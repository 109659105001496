@use "sass:math";


@mixin sidebar-width($borders, $width) {
  $sidebar-width: $width;

  @each $border in $borders {
    $direction: nth($border, 1);
    @if $direction == "all" {
      $size: map-get(map-get($borders, $direction), size);
      $sidebar-width: ($sidebar-width - (2 * $size));
    } @else if $direction == "right" {
      $size: map-get(map-get($borders, $direction), size);
      $sidebar-width: $sidebar-width - $size;
    } @else if $direction == "left" {
      $size: map-get(map-get($borders, $direction), size);
      $sidebar-width: $sidebar-width - $size;
    }
    width: $sidebar-width;
  }
}

[sidebar] {
  .nav {
    .nav-title {
      background-color: $nav-title-bg;
    }
  }
}

.sidebar {
  .sidebar-content {
    // margin-top: $layout-section-header-title-height;
    .sidebar-block {
      margin-bottom: 30px;
      .sidebar-block-title {
        font-size: 1.5rem;
        line-height: 1.5rem;
        margin-top: 0;
        margin-bottom: 10px;
        font-weight: 400;
        font-family: Adwired, Arial, Helvetica, sans-serif;
      }

      .explain {
        font-size: .625rem;
        line-height: .75rem;
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }

  }
  .sidebar-content {
    //@include global-scroll($main-color);
    .sidebar-block {
      .sidebar-block-title {
        color: $main-color;
      }
    }
  }

  .sidebar-nav {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    background: white;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    @include sidebar-width($sidebar-borders, $layout-sidebar-inner-width);
  }

  .nav {
    @include sidebar-width($sidebar-borders, $layout-sidebar-inner-width);
    flex-direction: column !important;
    .nav-item {
      position: relative;
      margin: 0;
      transition: background .3s ease-in-out;

      ul {
        max-height: 0;
        padding: 0;
        margin: 0;
        overflow-y: hidden;
        transition: max-height .3s ease-in-out;

        li {
          padding: 0;
          list-style: none;
        }
      }

      .nav-link {
        display: block;
        padding: $sidebar-nav-link-padding-y $sidebar-nav-link-padding-x;
        color: $main-color;
        text-decoration: none;
        background: $sidebar-nav-link-bg;
        @include borders($sidebar-nav-link-borders);
        @if $enable-sidebar-nav-rounded {
          border-radius: $border-radius;
        }

        &:hover {
          color: $sidebar-nav-link-hover-color !important;
          background: $sidebar-nav-link-hover-bg !important;
          @include borders($sidebar-nav-link-hover-borders);

          i {
            color: $sidebar-nav-link-hover-icon-color;
          }
        }

        &.active {
          color: white;
          background: $sidebar-nav-link-active-bg;
          @include borders($sidebar-nav-link-active-borders);

          i {
            color: white;
          }
        }

        [class^="icon-"], [class*=" icon-"] {
          display: inline-block;
          margin-top: -4px;
          vertical-align: middle;
        }

        i {
          width: 20px;
          margin: 0 math.div($sidebar-nav-link-padding-x, 2) 0 0;
          font-size: 14px;
          color: $sidebar-nav-link-icon-color;
          text-align: center;
        }

        .badge {
          float: right;
          margin-top: 2px;
          // margin-left: 10px;
        }

        &.nav-dropdown-toggle {

          &::before {
            position: absolute;
            top: ($sidebar-nav-link-padding-y + math.div($font-size-base, 4));
            right: $sidebar-nav-link-padding-x;
            display: block;
            width: ($font-size-base);
            height: ($font-size-base);
            padding: 0;
            font-size: $font-size-base;
            line-height: math.div($font-size-base * 3, 4);
            text-align: center;
            content: "\2039";
            transition: .3s;
          }
        }
      }

      &.nav-dropdown {
        &.open {
          background: $sidebar-nav-dropdown-bg;
          @include borders($sidebar-nav-dropdown-borders);
          @if $enable-sidebar-nav-rounded {
            border-radius: $border-radius;
          }
          > ul, > ol {
            // display: inline;
            max-height: 1000px;
          }

          .nav-link {
            color: $sidebar-nav-dropdown-color;
            border-left: 0 !important;
          }

          > .nav-link.nav-dropdown-toggle::before {
            transform: rotate(-90deg);
          }

          .nav-dropdown.open {
            border-left: 0;
          }
        }
        &.nt {
          transition: 0s !important;
          > ul, > ol {
            transition: 0s !important;
          }
          .nav-link {
            &.nav-dropdown-toggle {

              &::before {
                transition: 0s !important;
              }
            }
          }
        }
      }

      .nav-label {
        display: block;
        padding: math.div($sidebar-nav-link-padding-y, 8) $sidebar-nav-link-padding-x;
        color: $main-color;

        &:hover {
          color: $sidebar-color;
          text-decoration: none;
        }

        i {
          width: 20px;
          margin: -3px math.div($sidebar-nav-link-padding-x, 2) 0 0;
          font-size: 10px;
          color: $sidebar-nav-link-icon-color;
          text-align: center;
          vertical-align: middle;
        }
      }

      @if (lightness( white ) < 40) {
        .progress {
          background-color: lighten(white, 15%) !important;
        }
      }
    }
  }
}





@mixin sidebar($theme) {
  .sidebar {
    background: map-get($theme, 'sidebar-background-color');
    .sidebar-content {
      //@include global-scroll(map_get($theme,'main-color'));
      .sidebar-block {
        .sidebar-block-title {
          color: map-get($theme, 'main-color');
        }
      }
    }

    .sidebar-nav {
      background: map-get($theme, 'sidebar-background-color');
    }

    .nav {
      .nav-title {
        color: map-get($theme, 'main-color');
      }
      .nav-item {
        .nav-link {
          color: map-get($theme, 'main-color');
          &.active {
            color: map-get($theme, 'sidebar-nav-link-active-color');
            background: map-get($theme, 'sidebar-nav-link-active-bg');
            i {
              color: map-get($theme, 'sidebar-nav-link-active-icon-color');
            }
          }
        }

        .nav-label {
          color: map-get($theme, 'main-color');
        }

        @if (lightness( map-get($theme, 'sidebar-background-color') ) < 40) {
          .progress {
            background-color: lighten(map-get($theme, 'sidebar-background-color'), 15%) !important;
          }
        }
      }
    }
  }

}

