.modal-header {
  color: white;
  padding: 5px 10px;
  font-size: 0.75rem;

  .modal-title {
    font-size: 0.75rem;
    line-height: 1;
  }


}

.modal-footer {
  border-top: 0;
}


@media (min-width: 1024px) {
  .modal-xl {
    max-width: 1024px;
  }
}

.modal-close-button {
  color: white;
  padding: 0;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  outline: hidden;
}
