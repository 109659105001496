.modal-body.competitors {

  input {
    border: 0;
    background: transparent;
    border-bottom: 1px solid $brand-green;
    margin-bottom: 10px;

    &::placeholder {
      text-align: left;
    }

    &:focus::placeholder {
      visibility: hidden;
    }
  }

  .tabContainer {
    max-height: 300px;
    overflow-y: auto;
  }

  .nav.nav-tabs {
    background-color: $brand-green;
    padding-top: 5px;

    .nav-item {
      margin-left: 5px;

      .nav-link {
        color:white;
        cursor: default;

        &.active {
          color: $brand-green;
        }
      }
    }
  }

  .tab-content {
    border: 1px solid $brand-green;
    padding: 5px;
  }
}
