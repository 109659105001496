.sector-filter.dropdown {
  .dropdown-menu {
    width: $layout-sidebar-width - 30px;
    &.scrollable-menu {
      max-height: 300px;
      overflow-x: hidden;
      overflow-y: auto;

      .dropdown-item {
        white-space: nowrap;
        line-height: 1;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;

      }
    }
  }
}

.brand_query {
  input {
    height: 15px;
  }
}
.brand-table {
  margin-top: $brand-table-margin-top;
  .brand-table-body {
    height: $brand-table-body-height;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: $brand-table-padding-right;
    .brand-table-row {
      background-color: $brand-table-bg;
      height: 25px;
      line-height: 25px;

      &.hiddenBrand {
        background-color: red;
      }

      .brand-name-cell {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: $brand-table-cell-padding-left;
      }
      .brand-statement-cell {
        padding-right: $brand-table-cell-padding-left;
      }
      div {
        border-bottom: $brand-table-row-border-bottom;
      }
      &.selected {
        color: $brand-table-selected-color;
      }
    }
  }
}
