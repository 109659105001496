@mixin section-container() {
  &.overview {
    background-color: $overview-bg;
    margin-left: 0;
    margin-right: 0;

    .section-body {
      overflow: hidden;
      padding-left: $layout-hgap;
      padding-right: $layout-hgap;
    }
  }

  .section-card-header {
  }

  .section-body {
    min-height: 400px;
    flex-direction: row;
    display: flex;
    margin-bottom: $layout-vgap;
  }

  @media (max-width: 990px) {
    .section-body {
      min-height: 400px;
      flex-direction: column;
      display: flex;
      //margin-bottom: $layout-vgap;
    }

  }
}
.section-card {
  position: relative;
  margin-left: $layout-hgap;
  margin-right: $layout-hgap;
  margin-bottom: $layout-hgap;

  @include section-container();
}

