.zoom {
  position: fixed;
  background: white;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  .zoom-header {
    flex: 0 0 25px;
  }

  .zoom-body {
    flex: 1 1 100%;
    display:flex;
  }
}

.section-zoom {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;

}