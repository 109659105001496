[adminLayout] {
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  margin: auto;
  overflow-y: auto;

  .mobile-sidebar-toggler {
    display: none;
  }


  [sidebar] {
    flex: 0 0 $layout-sidebar-width;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .sidebar-header {
      z-index: 1;
      flex: 0 0 $layout-top-height;
    }

    .sidebar-content {
      flex: 1 1 100%;
      overflow-y: auto;
      overflow-x: hidden;
      padding-right: 15px;
      padding-left: 15px;
      display: -webkit-flex;
      display: flex;
      flex-direction: column;
    }

  }

  [content] {
    margin-top: 0;
    display: -webkit-flex;
    display: flex;
    min-height: 100vh;
    flex: 1 1 100%;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    //overflow:hidden;

    [top] {
      display: -webkit-flex;
      display: flex;
      flex: 0 0 auto;
      height: $layout-top-height;
      flex-flow: column;

      .top-toolbar.nav.navbar {
        padding: 0;
        height: $layout-top-toolbar-height;
        flex: 0 0 $layout-top-toolbar-height;
        display: -webkit-flex;
        display: flex;
        flex-flow: row;
      }

      .top-title {
        flex: 0 0 $layout-top-title-height;
        display: -webkit-flex;
        display: flex;
        flex-flow: column;
        .top-title-line {
          flex: 0 0 $layout-top-title-line-height;
        }
      }
    }

    [middle] {
      flex: 1 1 100%;

      &.full-height {
        height: calc(100vh - #{$layout-top-height})
      }

      &.overflow-hidden {
        overflow: hidden;
      }
    }

  }
}

.sidebar-hidden {
  [adminLayout] {
    [sidebar] {
      display: none;
    }

    [content] {
      margin-left: 0;
    }
  }
}

@media (max-width: $layout-width) {
  .mobile-sidebar-toggler {
    display: inherit !important;
  }
  .sidebar-toggler {
    display: none;
  }

  [adminLayout] {
    width: calc(100vw - 18px);
    [sidebar] {
      display: none;
      left: 0;
      right: calc(100vw - #{$layout-sidebar-width})
    }

    [content] {
      margin-left: 0;
    }
  }

  .sidebar-mobile-show {
    [adminLayout] {
      [sidebar] {
        display: -webkit-flex;
        display: flex !important;
      }

      [content] {
        margin-left: $layout-sidebar-width;
        margin-right: -$layout-sidebar-width;
      }
    }

  }
}

@media (max-width: $layout-sidebar-width) {
  [adminLayout] {
    display: none;
  }
  .toosmall {
    display: block !important;
  }
}

@media (max-height: 375px) {
  [adminLayout] {
    display: none;
  }
  .toosmall {
    display: block !important;
  }

}
