@mixin global-scroll($col){
  scrollbar-base-color: $col;
  scrollbar-face-color: white;
  scrollbar-3dlight-color: white;
  scrollbar-highlight-color: $col;
  scrollbar-track-color: $col;
  scrollbar-arrow-color: white;
  scrollbar-shadow-color: $col;
  scrollbar-dark-shadow-color: $col;

  &::-webkit-scrollbar {
    position: absolute;
    width: 10px;
    margin-left: -10px;
    -webkit-appearance: none;
  }

  // &::-webkit-scrollbar-button {    }

  &::-webkit-scrollbar-track {
    background-color: white;
    border-right: 0;//1px solid map_get($theme,'nav-title-bg');
    border-left: 0;//1px solid map_get($theme,'nav-title-bg');
  }

  // &::-webkit-scrollbar-track-piece {    }

  &::-webkit-scrollbar-thumb {
    height: 50px;
    background-color: $col;
    background-clip: content-box;
    border-color: transparent;
    border-style: solid;
    border-width: 1px 2px;
  }


  //scrollbar-color: map_get($theme,'chart-button-bg') white;
  //scrollbar-width: thin;


}

