small-chart-panel {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;

  .small-chart-panel {
    flex: 1 1 auto;
    flex-direction: column;
    display: flex;

    chart-content {
      display: flex;
      flex: 1 1 auto;
      background-image: linear-gradient(180deg, #fff 0, #d9d9d9);
      background-repeat: repeat-x;
      box-shadow: 0 2px 5px rgba(0, 0, 0, .3);

      .chartWrapper {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        box-shadow: $box-shadow;
        multi-hc {
          .row {
            margin: 0;

            .col-3, .col-4, .col-6, .col-12 {
              padding: 0;
            }
          }
        }

        adw-hc {
          flex: 1 1 auto;
          display: flex;

          adw-hc-single {
            flex: 1 1 auto;
            display: flex;

            .highcharts-container {
              flex: 1 1 auto;
            }
          }
        }
      }
    }

    .small-chart-title {
      text-align: left;
      font-weight: normal;
      font-family: $font-family-base;
      line-height: 12px;
      flex: 0 0 $sector-chart-title-height;
      font-size: 0.75rem;
      padding: 5px;
      margin: 0;
    }

    .chart-toolbar {
      flex: 1 1 auto;
      display: flex;
      flex-direction: row;
      //border-bottom: 1px solid rgba(192, 192, 192, 1);
      margin: 5px 0;

      .chart-toolbar-buttons {
        flex: 0 0 auto;
        margin-right: 5px;
        padding: 0;
        float: left;

        button {
          height: 40px;
          width: 40px;
          margin-right: 5px;
          margin-bottom: 0px;
          border: 0;
          background-size: 100%;
          box-shadow: $box-shadow;

          &.chart-download {
            background-image: url("/assets/img/buttons/download.png");
          }

          &.chart-switch {
            background-image: url("/assets/img/buttons/switch.png");
          }

          &.chart-compare {
            background-image: url("/assets/img/buttons/compare.png");
          }

          &.chart-topic-compare {
            background-image: url("/assets/img/buttons/comparetopics.png");
          }

          &.chart-columns-compare {
            background-image: url("/assets/img/buttons/comparetopics.png");
          }

          &.chart-dollar {
            background-image: url("/assets/img/buttons/dollar.png");
          }

          &.chart-deepdive {
            background-image: url("/assets/img/buttons/deepdive.png");
          }
        }
      }

      .chart-description {
        flex: 1 1 100%;
        float: left;
        display: block;
        flex-direction: column;
        line-height: 1rem;
        margin-left: 5px;

        .head {
          font-family: $font-family-bold;
        }

        span {
          //display: block;
          flex: 1 1 auto;
        }

        .chart-description-more-link {
          cursor: pointer;
        }

      }

    }

    .chart-popover {
      .popover {
        z-index: 1020;
        min-height: 300px;
        max-width: 600px;

        .popover-content {
          max-height: 300px;
          overflow-y: auto;
        }
      }
    }
  }
}
