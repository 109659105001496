.fromto {
  display: flex;
  flex-direction: row;

  div {
    height: 30px;
    display: flex;
    align-items: center;
    flex: 0 0 50%;

    label {
      flex: 0 0 auto;
    }

    input {
      flex: 1 1 auto;
    }

    .mat-datepicker-toggle {
      flex: 0 0 auto;
      width: 20px;
      margin:0;
      padding: 0;
      line-height: 30px;
      button {
        width: 20px;
        line-height: 30px;
        height: 30px;
      }
    }
  }
}
