.popover {
  z-index: 999990;
  max-width: 600px;
  padding: 0;
  .popover-title {
    padding: 5px 14px;
    font-size: $font-size-base;
    color: white;
  }
  .popover-content {
    max-height: 100px;
    padding: 5px 14px;
    overflow-y: auto;
    background-color: white;
    div.line {
      line-height: 1.1;
      margin-bottom: 10px;
    }
  }
}
