.overview {
  dyn-combi {
    dyn-matrix {
      background-image: linear-gradient(180deg, #fff 0, #d9d9d9);
      background-repeat: repeat-x;
    }
    dyn-cloud {
      background-image: linear-gradient(180deg, #fff 0, #d9d9d9);
      background-repeat: repeat-x;
    }
  }
}

dyn-combi {
  display: flex;
  flex-direction: column;

  dyn-matrix {
    display: block;
    flex: 1 1 50%;

    .zoom {
      cursor: move;
      fill: none;
      pointer-events: all;
    }

    g.overlay {
      text {
        fill: #777;
        font: 12px AdwiredBold;
      }
    }

    &.notrace {
      circle.trace {
        display: none;
      }
    }
  }

  dyn-cloud {
    display: block;
    flex: 1 1 50%;
  }

  text {
    font: 10px Adwired;
  }

  .axis path, .axis line {
    fill: none;
    stroke: #000;
    shape-rendering: crispEdges;
  }

  .x2.axis path, .x2.axis line {
    fill: none;
    stroke: none;
    shape-rendering: crispEdges;
  }

  .label {
    fill: #777;
  }

  .week.label {
    font: 500 16px Adwired;
    fill: #ddd;
  }

  .week.label.active {
    fill: #aaa;
  }

  .overlay {
    fill: none;
    pointer-events: all;
    cursor: ew-resize;
  }

  .toolbar {
    display: flex;
    flex-direction: row;
    padding: 0 20px;
    height: 30px;

    .btn {
      flex: 0 0 22px;
      height: 22px;
      margin: 4px 2px;
      border: 0;
      padding: 0;
    }

    nouislider {
      flex: 1 1 80%;
      height: 22px;
      margin-top: 11px;
    }
  }

}