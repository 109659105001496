@mixin borders($borders) {
  @each $border in $borders {
    $direction: nth($border, 1);
    @if $direction == "all" {
      $size: map-get(map-get($borders, $direction), size);
      $style: map-get(map-get($borders, $direction), style);
      $color: map-get(map-get($borders, $direction), color);
      border: $size $style $color;
    } @else if $direction == "top" {
      $size: map-get(map-get($borders, $direction), size);
      $style: map-get(map-get($borders, $direction), style);
      $color: map-get(map-get($borders, $direction), color);
      border-top: $size $style $color;
    } @else if $direction == "right" {
      $size: map-get(map-get($borders, $direction), size);
      $style: map-get(map-get($borders, $direction), style);
      $color: map-get(map-get($borders, $direction), color);
      border-right: $size $style $color;
    } @else if $direction == "bottom" {
      $size: map-get(map-get($borders, $direction), size);
      $style: map-get(map-get($borders, $direction), style);
      $color: map-get(map-get($borders, $direction), color);
      border-bottom: $size $style $color;
    } @else if $direction == "left" {
      $size: map-get(map-get($borders, $direction), size);
      $style: map-get(map-get($borders, $direction), style);
      $color: map-get(map-get($borders, $direction), color);
      border-left: $size $style $color;
    }
  }
}


@mixin hover-focus {
  &:focus,
  &:hover {
    @content
  }
}

@mixin ident {
  padding-left: $layout-hgap;
  padding-right: $layout-hgap;
}

@mixin v-ident {
  padding-top: $layout-vgap;
  padding-bottom: $layout-vgap;
}

@mixin b-ident {
  padding-bottom: $layout-vgap;
}

@mixin box-shadow($shadow...) {
  @if $enable-shadows {
    box-shadow: $shadow;
  }
}

@mixin transition($transition...) {
  @if $enable-transitions {
    @if length($transition) == 0 {
      transition: $transition-base;
    } @else {
      transition: $transition;
    }
  }
}

@mixin link($font-weight:$font-weight-normal,$color:$link-color, $disabled-color: $btn-link-disabled-color, $hover-color:$link-hover-color, $hover-decoration:$link-hover-decoration) {
  font-weight: $font-weight;

  color: $color;
  border-radius: 0;

  &,
  &:active,
  &.active,
  &:disabled {
    background-color: transparent;
    @include box-shadow(none);
  }
  &,
  &:focus,
  &:active {
    border-color: transparent;
  }
  @include hover {
    border-color: transparent;
  }
  @include hover-focus {
    color: $hover-color;
    text-decoration: $hover-decoration;
    background-color: transparent;
  }
  &:disabled {
    color: $disabled-color;

    @include hover-focus {
      text-decoration: none;
    }
  }

}