//@font-face {
//  font-family: 'Adwired';
//  font-style: normal;
//  src: url('~assets/fonts/Adwired.woff') format('woff'), url('~assets/fonts/Adwired.ttf') format('truetype');
//}
//
//@font-face {
//  font-family: 'AdwiredBold';
//  font-style: normal;
//  src: url('~assets/fonts/AdwiredBold.woff') format('woff'), url('~assets/fonts/AdwiredBold.ttf') format('truetype');
//}


.bold-font {
  font-weight: normal;
  font-family: 'AdwiredBold', 'Adwired', Georgia, "Times New Roman", Times, serif;
}
