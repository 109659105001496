.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.app {
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}


div.toosmall {
  display: none;
  position: fixed;
  top: 50vh;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  vertical-align: middle;
}

