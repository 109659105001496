[top], .top {
  background: $top-background;
  padding-right: 15px;
  padding-left: 15px;
  align-content: stretch;

  .navbar {
    color: $top-nav-color;
    font-size: $top-nav-font-size;
    display: flex;
    flex-direction: row;
    justify-content: left;

    .nav {
      flex-direction: row;
      align-items: center;

      .nav-link {
        @include link($color: $top-nav-color);
        padding: 0;
      }
    }
  }

  .top-title {
    padding: $layout-top-title-padding-top 0 $layout-top-title-padding-bottom 0;

    .top-title-line {
      font-size: 1.75rem;
      line-height: 1.75rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .brand-text {
        color: $main-color;
        font-weight: 400;
        font-family: AdwiredBold, Arial, Helvetica, sans-serif;
      }
    }

  }
}
