report-component {
  .card-block.report-selector {
    border-bottom: 1px solid lightgray;
  }

  .card-block.report-details {

    .row {
      margin-bottom: 5px;
    }

    .form-control-label {
    }

    .form-control-label {
      line-height: 24px;
      margin-right: 5px;

      &.main-title {
      }

      &.sub-title {
      }
    }
  }
}