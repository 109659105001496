@use "sass:math";

.card {
  margin-bottom: 1.5 * $spacer;
}

.card-header {
  line-height: 24px;

  .icon-bg {
    display: inline-block;
    padding: $card-spacer-y $card-spacer-x !important;
    margin-top: -$card-spacer-y;
    margin-right: $card-spacer-x;
    margin-bottom: -$card-spacer-y;
    margin-left: -$card-spacer-x;
    line-height: inherit;
    color: $card-icon-color;
    vertical-align: bottom;
    background: $card-icon-bg;
    border-right: $card-border-width solid $card-border-color;
  }

  .btn {
    margin-top: - $input-btn-padding-y;
  }
  .btn-sm {
    margin-top: - $input-btn-padding-y-sm;
  }
  .btn-lg {
    margin-top: - $input-btn-padding-y-lg;
  }
}
//
.card-footer {

  ul {
    display: table;
    width: 100%;
    padding: 0;
    margin: 0;
    table-layout: fixed;

    li {
      display: table-cell;
      padding: 0 $card-spacer-x;
      text-align: center;
    }
  }
}



// Card Actions
.card-header {
  > i {
    margin-right: math.div($spacer, 2);
  }

  .card-actions {
    position: absolute;
    top: 0;
    right: 0;
    //height: inherit;

    a, button {
      outline: none;
      display: block;
      float: left;
      width: 50px;
      padding: $card-spacer-y 0;
      margin: 0 !important;
      color: $body-color;
      text-align: center;
      background: transparent;
      border: 0;
      border-left: 1px solid $border-color;
      box-shadow: 0;

      &:hover {
        text-decoration: none;
      }

      [class^="icon-"], [class*=" icon-"] {
        display: inline-block;
        vertical-align: middle;
      }

      i {
        display: inline-block;
        transition: .4s;
      }

      .r180 {
        transform: rotate(180deg);
      }
    }

    .input-group {
      width: 230px;
      margin: 6px;

      .input-group-addon {
        background: #fff;
      }

      input {
        border-left: 0;
      }
    }
  }
}


.card {
  &.drag, .drag {
    cursor: move;
  }
}

//.card-placeholder {
//  background: rgba(0,0,0,.025);
//  border: 1px dashed $gray-light;
//}



.card-inverse {
  color: #fff;

  .text-muted {
    color: rgba(255,255,255,.6) !important;
  }

}


.card-main {
  background-color: #ffffff;

  .card-header .card-actions a, .card-header .card-actions button {
    color: white;
    border: 0;
  }
  .card-header, .card-footer {
    color: white;
    padding: 0.25rem 0.9375rem;
  }
}

.card-header, .card-footer {
  padding: 0.25rem 0.9375rem;
}
